import React from 'react'
import { Header, Responsive, Image } from 'semantic-ui-react'

import { getRawImage } from '../../utils/images'

const Bubble = ({
  show,
  title,
  text,
  signature,
  image,
  inverted,
  className
}) => {
  return (
    show ? (
      <div className='only-computer' {...Responsive.onlyComputer}>
        <div
          className={`bubble ${inverted ? 'red' : ''} ${image ? 'with-image' : 'no-image'
            } ${className || ''}`}
        >
          {image && (
            <Image
              src={getRawImage({
                cloudinary: image.cloudinary,
                width: 115,
                format: 'png'
              })}
              className='image'
              alt={image.cloudinary}
            />
          )}
          <div className='content'>
            {title && <Header as='h3'>{title}</Header>}
            <p dangerouslySetInnerHTML={{ __html: text }} />
            {signature && <strong>{signature}</strong>}
          </div>
          <div className='triangle' />
        </div>
      </div>
    ) : null
  )
}

export default Bubble
