import React from 'react'
import { connect } from 'react-redux'
import { Segment, Header, Icon } from 'semantic-ui-react'

import Modal from './Modal'

const NotificationModal = ({ type, text }) => {
  return (
    <Modal id='notification' closeIcon size='mini'>
      <Segment textAlign='center' basic vertical>
        {type === 'success' && <Icon className='ta-checkmark' size='massive' color='green' />}
        {type === 'error' && <Icon className='ta-close' size='massive' color='red' />}
        {!type && <Icon className='ta-info' size='massive' color='blue' />}
      </Segment>
      {text && <Header textAlign='center'>{text}</Header>}
    </Modal>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapStateToProps = ({ modals: { data } }) => {
  return {
    type: data.type,
    text: data.text
  }
}

export default connect(mapStateToProps)(NotificationModal)
