import React from 'react'
import { t } from 'i18next'
import { Modal, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { modalActions } from './state'

const CustomModal = ({
  id,
  modalId,
  toggleModal,
  open,
  header,
  children,
  closeIcon,
  okLabel = t('general.yes'),
  cancelLabel = t('general.no'),
  onConfirm = null,
  onClose = null,
  size = 'tiny',
  disabled
}) => {
  const handleOk = () => {
    onConfirm && onConfirm()
    toggleModal(id)
  }

  const handleCancel = () => {
    onClose && onClose()
    toggleModal(id)
  }

  return (
    <Modal
      open={open && id === modalId}
      onClose={handleCancel}
      size={size}
      closeIcon={closeIcon}
      centered={false}
    >
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      {onConfirm && (
        <Modal.Actions>
          <Button color='red' inverted onClick={handleCancel}>
            {cancelLabel}
          </Button>
          <Button color='red' onClick={handleOk} disabled={disabled}>
            {okLabel}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/

const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

const mapStateToProps = ({ modals }) => ({
  open: modals.open,
  modalId: modals.modalId
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal)
