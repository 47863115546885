import React from 'react'
import { connect } from 'react-redux'
import fetchJsonp from 'fetch-jsonp'

import {
  Segment,
  Icon,
  List,
  Header,
  Input,
  Container,
  Grid,
  Responsive,
  Checkbox,
} from 'semantic-ui-react'
import Img from 'gatsby-image'
import { tabletBreakpoint } from '../../utils/constants'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { Link } from '../../locales'
import { withTranslation, Trans } from 'react-i18next'

import { getFooterImage } from '../../utils/images'
import Modal from '../Modals/Modal'
import CookieConfirmation from '../Common/CookieConfirmation'
import { modalActions } from '../Modals/state'
import { filtersActions } from '../Filters/state'

import {
  getSharedCategories,
  relocalizeLink,
  unescape,
} from '../../utils/helpers'

class Footer extends React.Component {
  state = {
    email: '',
    errorMsg: '',
    checkbox: false,
    submitted: false,
  }

  handleInputChange = event => {
    this.setState({
      email: event.target.value,
    })
  }

  handleCheckboxChange = event => {
    this.setState({
      checkbox: !this.state.checkbox,
    })
  }

  handleLangChange = lang => {
    const { i18n, pageContext } = this.props

    i18n.changeLanguage(lang)

    navigate(relocalizeLink(i18n.language, pageContext))
  }

  handleNewsletterSignup = () => {
    const { i18n } = this.props
    const lang = i18n.language
    const apiUrlLink =
      lang === 'de'
        ? 'https://info.tourasia.ch/l/952092/2021-12-21/dpv?'
        : 'https://info.tourasia.ch/l/952092/2022-01-11/mch?'

    const email = this.state.email
    const checkbox = this.state.checkbox

    if (!email || !checkbox) {
      return false
    }

    const query = {
      email: email,
    }

    const apiUrl = apiUrlLink + new URLSearchParams(query).toString()

    fetchJsonp(apiUrl)
      .then(function(response) {
        if (response) {
          return response.json()
        }
      })
      .then(function(json) {
        console.log('query was:', query)
      })
      .catch(function(ex) {
        console.log('parsing failed', ex)
      })

    this.setState({
      email: '',
      submitted: true,
    })
  }

  render() {
    const { i18n, t, resetFilters } = this.props
    const { submitted } = this.state

    return (
      <StaticQuery
        query={graphql`
          query {
            allFirestoreCategory(
              filter: { type: { eq: "Themen" } }
              sort: { fields: [title], order: ASC }
            ) {
              edges {
                node {
                  id
                  title
                  service
                  type
                  lang
                }
              }
            }
            allFirestoreCountry(
              filter: { sortOrder: { ne: 0 } }
              sort: { fields: [sortOrder], order: ASC }
            ) {
              edges {
                node {
                  id
                  title
                  slug
                  lang
                  sortOrder
                }
              }
            }
            allWordpressPage(sort: { fields: [menu_order], order: ASC }) {
              edges {
                node {
                  id
                  title
                  slug
                  menu_order
                  acf {
                    menu_footer
                    language
                    alt_nav_title
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const countries = data.allFirestoreCountry.edges.filter(
            ({ node: { lang } }) => lang === i18n.language
          )
          const allCategories = data.allFirestoreCategory.edges.filter(
            ({ node: { lang } }) => lang === i18n.language
          )
          const categories = getSharedCategories(allCategories)
          const wpPages = data.allWordpressPage.edges
            ? data.allWordpressPage.edges.filter(
                ({ node: { acf, slug } }) => acf.language === i18n.language
              )
            : []

          const gdprCheckBox = () => {
            const lang = i18n.language
            let text = ''
            if (lang === 'de') {
              text = (
                <span>
                  Hiermit willige ich ein, dass die von mir angegebenen
                  personenbezogenen Daten für den regelmässigen Versand von
                  personalisierten Newslettern von Tourasia Roemer AG
                  verarbeitet werden dürfen. Mein Einverständnis hierzu kann ich
                  jederzeit für die Zukunft widerrufen und damit den Versand von
                  Newslettern wieder abbestellen. Jeder Newsletter enthält im
                  Footer einen Abmeldelink. Weitere Informationen zur
                  Verarbeitung personenbezogenen Daten gehen aus der{' '}
                  <Link to={`/datenschutz`} lang={i18n.language}>
                    Datenschutzerklärung
                  </Link>{' '}
                  hervor.
                </span>
              )
            }
            if (lang === 'fr') {
              text = (
                <span>
                  Par la présente, j'accepte que les données personnelles que
                  j'ai indiquées soient traitées par Tourasia Roemer AG pour
                  l'envoi régulier de newsletters personnalisées. Je peux à tout
                  moment révoquer mon accord à ce sujet pour l'avenir et me
                  désabonner ainsi de l'envoi de newsletters. Chaque newsletter
                  contient un lien de désinscription dans le pied de page. Vous
                  trouverez de plus amples informations sur le traitement des
                  données personnelles dans la déclaration de protection des
                  données.
                </span>
              )
            }
            return (
              <Checkbox
                value='accepted'
                onChange={this.handleCheckboxChange}
                className='newsletter-checkbox'
                label={<label>{text}</label>}
              />
            )
          }

          return (
            <div>
              <Segment inverted color='red' padded='very' vertical>
                <Container>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Header as='h2' inverted>
                          <Header.Content>
                            {t('footer.newsletter')}
                            <Header.Subheader>
                              {t('footer.newsletter-details')}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        {(!submitted && (
                          <>
                            <Modal id='newsletter' okLabel='OK' size='tiny' />
                            <Responsive
                              as={Input}
                              minWidth={tabletBreakpoint}
                              name='email'
                              type='email'
                              size='large'
                              fluid
                              action={{
                                inverted: true,
                                labelPosition: 'right',
                                size: 'large',
                                icon: <i className='icon ta-arrow-1-right' />,
                                content: t('footer.sign-up'),
                                onClick: this.handleNewsletterSignup,
                              }}
                              placeholder={t('footer.newsletter-placeholder')}
                              value={this.state.email}
                              onChange={this.handleInputChange}
                              aria-label='newsletter'
                            />
                            <Responsive
                              as={Input}
                              maxWidth={tabletBreakpoint - 1}
                              name='email'
                              type='email'
                              size='medium'
                              fluid
                              action={{
                                inverted: true,
                                size: 'medium',
                                content: t('footer.sign-up'),
                                onClick: this.handleNewsletterSignup,
                              }}
                              placeholder={t('footer.newsletter-placeholder')}
                              value={this.state.email}
                              onChange={this.handleInputChange}
                              aria-label='newsletter'
                            />
                            {gdprCheckBox()}
                          </>
                        )) || (
                          <Header.Content>
                            <Header.Subheader className='newsletter-thankyou'>
                              <Trans i18nKey='footer.newsletter-subscription-success'>
                                <div className='newsletter-thankyou_header'>
                                  Vielen Dank!
                                </div>
                                <div className='newsletter-thankyou_p1'>
                                  Es freut uns, dass Sie sich für unseren
                                  Newsletter angemeldet haben.
                                </div>
                                <div className='newsletter-thankyou_p2'>
                                  Sie werden in Kürze eine Bestätigungs-E-Mail
                                  von uns erhalten.
                                </div>
                              </Trans>
                            </Header.Subheader>
                          </Header.Content>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Segment>

              <Container>
                <Segment padded='very' vertical>
                  <Grid divided inverted stackable>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <Trans i18nKey={'footer.hours'}>
                          <Header as='h3' color='red'>
                            Wir beraten Sie gerne
                          </Header>
                          <p>
                            <b>Montag bis Freitag 09:00 – 18:00</b>
                          </p>
                        </Trans>
                        <p>
                          <Trans i18nKey='footer.address'>
                            tourasia Roemer AG
                            <br />
                            Grindelstrasse 5<br />
                            8304 Wallisellen
                          </Trans>
                          <br />
                          <a href={`mailto:${t('footer.email')}`}>
                            {t('footer.email')}
                          </a>{' '}
                          <br />
                          <a href={`tel:${t('footer.phone')}`}>
                            {t('footer.phone')}
                          </a>
                        </p>
                        <p>© {new Date().getFullYear()} tourasia</p>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Grid.Column width={4}>
                          <Header as='h3' color='red'>
                            {t('footer.fav-destination')}
                          </Header>
                          <List link>
                            {countries.map(({ node: { id, title, slug } }) => (
                              <List.Item key={id}>
                                <Link
                                  to={`/destinations/${slug}`}
                                  lang={i18n.language}
                                >
                                  {title}{' '}
                                  <i className='icon red ta-arrow-1-right' />
                                </Link>
                              </List.Item>
                            ))}
                          </List>
                        </Grid.Column>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Header as='h3' color='red'>
                          {t('footer.trips')}
                        </Header>
                        <List link>
                          {categories.map(category => (
                            <List.Item key={category}>
                              <Link
                                to={`/destinations/all?categories=${category}`}
                                state={{ sharedCategories: [category] }}
                                lang={i18n.language}
                              >
                                <span onClick={() => resetFilters()}>
                                  {category}{' '}
                                  <i className='icon red ta-arrow-1-right' />
                                </span>
                              </Link>
                            </List.Item>
                          ))}
                        </List>
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Header as='h3' color='red'>
                          {t('footer.customerservice')}
                        </Header>
                        <List link>
                          {wpPages
                            .filter(
                              ({ node: { acf } }) =>
                                acf && acf.menu_footer === 'kundenservice'
                            )
                            .map(
                              ({
                                node: {
                                  id,
                                  slug,
                                  title,
                                  acf: { alt_nav_title },
                                },
                              }) => (
                                <List.Item key={id}>
                                  <Link to={`/${slug}`} lang={i18n.language}>
                                    {unescape(alt_nav_title) || unescape(title)}{' '}
                                    <i className='icon red ta-arrow-1-right' />
                                  </Link>
                                </List.Item>
                              )
                            )}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>

              <Segment inverted className='darkgray' vertical>
                <Container>
                  <Grid columns={4} stackable>
                    {wpPages
                      .filter(
                        ({ node: { acf } }) =>
                          acf && acf.menu_footer === 'bottom'
                      )
                      .map(
                        ({
                          node: {
                            id,
                            slug,
                            title,
                            acf: { alt_nav_title },
                          },
                        }) => (
                          <Grid.Column
                            key={id}
                            textAlign='center'
                            verticalAlign='middle'
                          >
                            <List inverted link>
                              <List.Item key={id}>
                                <Link to={`/${slug}`} lang={i18n.language}>
                                  <p>
                                    {unescape(alt_nav_title) || unescape(title)}
                                  </p>
                                </Link>
                              </List.Item>
                            </List>
                          </Grid.Column>
                        )
                      )}
                  </Grid>
                </Container>
              </Segment>

              <Container>
                <Grid padded='vertically' textAlign='center'>
                  <Grid.Column mobile={5} tablet={5} computer={2}>
                    <a
                      href='https://www.facebook.com/tourasia.ch/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='tourasia at Facebook'
                    >
                      <Icon name='facebook' size='huge' color='red' />
                    </a>
                  </Grid.Column>

                  <Grid.Column mobile={6} tablet={6} computer={2}>
                    <a
                      href='https://www.twitter.com/TourasiaSwiss'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='tourasia at Twitter'
                    >
                      <Icon name='twitter' size='huge' color='red' />
                    </a>
                  </Grid.Column>

                  <Grid.Column mobile={5} tablet={5} computer={2}>
                    <a
                      href='https://www.instagram.com/tourasia.ch/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='tourasia at Instagrram'
                    >
                      <Icon name='instagram' size='huge' color='red' />
                    </a>
                  </Grid.Column>

                  <Grid.Column mobile={16} tablet={16} computer={2} />
                  {i18n.language === 'de' ? (
                    <React.Fragment>
                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.tourcert.org/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='tourcert'
                        >
                          <Img
                            alt='tourcert'
                            fixed={getFooterImage({
                              cloudinary: 'footer/tourcert-de',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.srv.ch/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='srv'
                        >
                          <Img
                            alt='srv'
                            fixed={getFooterImage({
                              cloudinary: 'footer/srv-de',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.garantiefonds.ch/portrait/der-garantiefonds'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='reisegarantie'
                        >
                          <Img
                            alt='reisegarantie'
                            fixed={getFooterImage({
                              cloudinary: 'footer/reisegarantie-de',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://tps.travel/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='tps'
                        >
                          <Img
                            alt='tps'
                            fixed={getFooterImage({
                              cloudinary: 'footer/tps_logo',
                            })}
                          />
                        </a>
                      </Grid.Column>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.tourcert.org/en/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='tourcert'
                        >
                          <Img
                            alt='tourcert'
                            fixed={getFooterImage({
                              cloudinary: 'footer/tourcert-fr',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.srv.ch/fr/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='srv'
                        >
                          <Img
                            alt='srv'
                            fixed={getFooterImage({
                              cloudinary: 'footer/srv-fr',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://www.garantiefonds.ch/fr/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='reisegarantie'
                        >
                          <Img
                            alt='reisegarantie'
                            fixed={getFooterImage({
                              cloudinary: 'footer/reisegarantie-fr',
                            })}
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column mobile={8} tablet={4} computer={2}>
                        <a
                          href='https://tps.travel/fr/'
                          target='_blank'
                          rel='noopener noreferrer'
                          aria-label='tps'
                        >
                          <Img
                            alt='tps'
                            fixed={getFooterImage({
                              cloudinary: 'footer/tps_logo',
                            })}
                          />
                        </a>
                      </Grid.Column>
                    </React.Fragment>
                  )}
                </Grid>
              </Container>

              <CookieConfirmation />
            </div>
          )
        }}
      />
    )
  }
}
/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal,
  resetFilters: filtersActions.resetFilters,
}

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Footer)
)
