import React from 'react'
import { navigate } from 'gatsby'
import { Segment, Header, Grid } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { getFluidImage } from '../../utils/images'
import { localizeLink } from '../../utils/helpers'

async function fetchUrl ({ id, collection, lang }) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection(collection)
    .where('lang', '==', lang)
    .where('baseId', '==', id)
    .get()

  if (querySnapshot.empty) {
    return ''
  }

  const item = querySnapshot.docs[0].data()
  return `/${lang}/destinations/${item.countrySlug}/${collection}/${item.slug}`
}

const AdditionalOffers = ({ country, t, i18n, relations = [] }) => {
  function handleServiceRedirect ({ id, type }) {
    return fetchUrl({ id, collection: type, lang: i18n.language }).then(url =>
      url ? navigate(localizeLink(url, i18n.language)) : false
    )
  }

  return (
    <Segment padded='very' vertical>
      {!!relations.length && (
        <Header as='h3'>
          {t('additional-offers.header')} {country}
        </Header>
      )}
      <Grid>
        {relations.map(({ id, title, image, type }) => (
          <Grid.Column
            key={id}
            mobile={16}
            tablet={8}
            computer={8}
            largeScreen={8}
            widescreen={8}
          >
            <div
              onClick={() => handleServiceRedirect({ id, type })}
              style={{ cursor: 'pointer' }}
            >
              <Img
                fluid={getFluidImage({ cloudinary: image.cloudinary })}
                alt={image.title}
              />
              <Segment vertical clearing>
                <Header as='h3' color='red' floated='left'>
                  {title}
                </Header>
              </Segment>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    </Segment>
  )
}

export default withTranslation()(AdditionalOffers)
