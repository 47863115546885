import React from 'react'
import { Segment } from 'semantic-ui-react'
import { StaticQuery, graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'

import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'

import NotificationModal from '../Modals/NotificationModal'

const Navigation = props => {
  const { countries, categories, i18n, t, pageContext } = props

  const travelTypes = [
    {
      title: t('nav-main.travel-types.tours'),
      service: 'tours'
    },
    {
      title: t('nav-main.travel-types.hotels'),
      service: 'hotels'
    },
    {
      title: t('nav-main.travel-types.trips'),
      service: 'trips'
    }
  ]
  const categoryTypes = [
    {
      title: t('nav-main.category-types.arten'),
      type: 'Arten'
    },
    {
      title: t('nav-main.category-types.themen'),
      type: 'Themen'
    }
  ]

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPage(sort: { fields: [menu_order], order: ASC }) {
            edges {
              node {
                id
                wordpress_id
                title
                slug
                menu_order
                acf {
                  language
                  menu_group
                  menu_section
                  top_menu
                  alt_nav_title
                }
              }
            }
          }
        }
      `}
      render={({ allWordpressPage }) => {
        const pages = allWordpressPage.edges
          ? allWordpressPage.edges.filter(
              ({ node: { acf } }) => acf.language === i18n.language
            )
          : []

        return (
          <Segment basic style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div className='navigation'>
              <div className='max-tablet'>
                <NavMobile
                  countries={countries}
                  categories={categories}
                  wpPages={pages}
                  travelTypes={travelTypes}
                  categoryTypes={categoryTypes}
                  pageContext={pageContext}
                />
              </div>
              <div className='min-tablet'>
                <NavDesktop
                  countries={countries}
                  categories={categories}
                  wpPages={pages}
                  travelTypes={travelTypes}
                  categoryTypes={categoryTypes}
                  pageContext={pageContext}
                />
              </div>
              <NotificationModal />
            </div>
          </Segment>
        )
      }}
    />
  )
}

export default withTranslation()(Navigation)
