import React from 'react'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

import { relocalizeLink } from '../../utils/helpers'

const LanguageSwitcher = props => {
  const handleLangChange = lang => {
    const { i18n, pageContext } = props

    i18n.changeLanguage(lang)

    navigate(relocalizeLink(i18n.language, pageContext))
  }

  const { i18n } = props

  return (
    <div className='language-switcher'>
      <span
        onClick={() => handleLangChange('de')}
        className={`language ${i18n.language === 'de' ? 'active' : ''}`}
      >
        <span>DE</span>
      </span>

      <span className='language-separator'>|</span>

      <span
        onClick={() => handleLangChange('fr')}
        className={`language ${i18n.language === 'fr' ? 'active' : ''}`}
      >
        <span>FR</span>
      </span>
    </div>
  )
}

export default withTranslation()(LanguageSwitcher)
