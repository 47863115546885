import React from 'react'
import { Icon } from 'semantic-ui-react'

const StarCounter = ({ count = 0, color = 'black' }) => {
  if (!count) {
    return ''
  }

  let halfStar = false
  let stars = 0

  if (isNaN(count)) {
    halfStar = count.split('/')[1]
    stars = Array(Math.floor(count.split('/')[0].length)).fill()
  } else {
    // eslint-disable-next-line
    halfStar = count != Math.floor(count)
    stars = Array(Math.floor(count)).fill()
  }

  return (
    <span style={{ position: 'relative', top: '-0.3em', whiteSpace: 'nowrap' }}>
      {stars.map((_, i) => (
        <Icon key={i} className='ta-hotel-t-outline' size='tiny' fitted style={{ color }} />
      ))}
      {halfStar ? (
        <Icon
          className='ta-hotel-t-outline'
          size='mini'
          fitted
          style={{ position: 'relative', top: '-0.4em', color }}
        />
      ) : (
        ''
      )}
    </span>
  )
}

export default StarCounter
