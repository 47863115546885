import React from 'react'
import { withTranslation } from 'react-i18next'

const Checkbox = ({
  checked = false,
  label,
  className,
  onChange,
  disabled = false,
}) => {
  return (
    <label className={className}>
      <input
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  )
}

export default withTranslation()(Checkbox)
