import React from 'react'
import { Segment, Card, Button } from 'semantic-ui-react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withTranslation } from 'react-i18next'

import WishlistButton from '../Wishlist/WishlistButton'

/* TOUR-869 If price is more than 999999 the price box will not shown */
const MAX_PRICE = 1000000

const PriceCard = ({
  item,
  header,
  price,
  content,
  type,
  centered,
  t,
  showPrice
}) => {
  return (
    <Segment vertical basic padded>
      {showPrice && (price < MAX_PRICE) && (
        <Card fluid raised style={{ margin: 0 }}>
          <Card.Content textAlign={`${centered ? 'center' : 'left'}`}>
            <Card.Header
              textAlign='center'
              as='h3'
              style={{ marginBottom: 16 }}
            >
              <small>{header}</small> CHF {price}
            </Card.Header>
            <Card.Description className='price-card-description'>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Card.Description>
          </Card.Content>
        </Card>
      )}

      {type === 'tours' ? (
        <AnchorLink href='#packages-list'>
          <Button fluid color='red' style={{ padding: '15px 0' }}>
            {t(`wishlist-button.add`)}
          </Button>
        </AnchorLink>
      ) : (
        <WishlistButton item={item} />
      )}
    </Segment>
  )
}

export default withTranslation()(PriceCard)
