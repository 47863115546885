import React from 'react'
import { Header, Container, Grid, Segment } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'

import Layout from '../components/Layouts/PageLayout'
import Share from '../components/Common/Share'
import ImageHeaderSlider from '../components/Items/ImageHeaderSlider'
import Bubble from '../components/Common/Bubble'
import { capitalize } from '../utils/helpers'
import Breadcrumb from '../components/Common/Breadcrumb'
import PageHeader from '../components/Items/PageHeader'
import ImageSectionSlider from '../components/Items/ImageSectionSlider'
import PriceCard from '../components/Items/PriceCard'
import { getRawImage } from '../utils/images'

import AdditionalOffers from '../components/Items/AdditionalOffers'

const TripPage = props => {
  const { data, pageContext, t } = props

  const {
    title,
    intro,
    program,
    collection,
    categories,
    country,
    countrySlug,
    region,
    regionSlug,
    location,
    locationSlug,
    images,
    slug,
    teaser,
    seo,
    relations
  } = data.firestoreTrip
  const item = data.firestoreTrip

  return (
    <div>
      <Helmet>
        <title>{seo.htmlTitle}</title>

        <meta name='description' content={seo.description} />
        <meta
          name='image'
          content={getRawImage({ cloudinary: images.cloudinary })}
        />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={seo.description} />
        <meta
          property='og:image'
          content={getRawImage({ cloudinary: images.cloudinary })}
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='tourasia' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={seo.description} />
        <meta
          name='twitter:image'
          content={getRawImage({ cloudinary: images.cloudinary })}
        />
      </Helmet>

      <Layout pageContext={pageContext}>
        <ImageHeaderSlider images={images} />

        {(teaser.header || teaser.content) && (
          <Bubble title={teaser.header} text={teaser.content} inverted />
        )}

        <PageHeader categories={categories} title={seo.h1} />

        <Segment vertical padded='very' className='gradient' inverted>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column computer={12}>
                  <Breadcrumb
                    service={collection}
                    country={country}
                    countrySlug={countrySlug}
                    region={region}
                    regionSlug={regionSlug}
                    location={location}
                    locationSlug={locationSlug}
                    title={title}
                  />
                </Grid.Column>
                <Grid.Column textAlign='right' computer={4}>
                  <Share color='grey' />
                </Grid.Column>
              </Grid.Row>

              <Grid.Column computer={11} tablet={11}>
                <Segment vertical padded='very'>
                  <Header as='h2' color='red'>
                    {t('trip-page.program')}
                  </Header>
                  <p>{intro}</p>
                  {images && (
                    <ImageSectionSlider
                      lightboxId={slug}
                      images={images}
                      withLightbox
                    />
                  )}
                </Segment>
              </Grid.Column>

              <Grid.Column computer={5} tablet={5}>
                <PriceCard
                  header={`${t('trip-package.trip')} ${capitalize(
                    item.price && item.price.type
                  )} ${t('trip-package.from')}`}
                  content={program}
                  price={item.price && item.price.value}
                  type={item.collection}
                  item={item}
                  showPrice
                />
              </Grid.Column>
            </Grid>

            <AdditionalOffers country={country} relations={relations} />
          </Container>
        </Segment>
      </Layout>
    </div>
  )
}

export default withTranslation()(TripPage)

/**************************************************************
 * GRAPHQL
 **************************************************************/
export const pageQuery = graphql`
  query TripQuery($slug: String!, $lang: String!) {
    firestoreTrip(slug: { eq: $slug }, lang: { eq: $lang }) {
      title
      lang
      intro
      country
      collection
      categories
      region
      location
      teaser {
        header
        content
      }
      seo {
        htmlTitle
        description
        h1
      }
      relations {
        id
        type
        title
        image {
          id
          cloudinary
          title
          alt
        }
      }
      program
      price {
        type
        value
      }
      images {
        id
        cloudinary
        transformation
        title
        alt
      }

      coverImage
      slug
      countrySlug
      regionSlug
      locationSlug
    }
  }
`
