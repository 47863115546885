import React from 'react'
import { connect } from 'react-redux'
import { StaticQuery, graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import Navigation from 'components/Navigations/Navigation'
import Footer from 'components/Footer/Footer'
import ScrollToTopButton from 'components/Common/ScrollToTopButton'
import { navigationActions } from 'components/Navigations/state'
import { filtersActions } from 'components/Filters/state'
import { scrollToTop, relocalizeLink } from 'utils/helpers'
import { LIVE_URL } from 'utils/constants'

class PageLayout extends React.Component {
  componentDidMount = () => {
    const { resetFilters } = this.props
    resetFilters()

    scrollToTop()
  }

  render () {
    const { children, i18n, pageContext, className } = this.props

    const lang = i18n.language
    const otherLang = lang === 'de' ? 'fr' : 'de'
    const alternateHref = LIVE_URL + relocalizeLink(otherLang, pageContext)

    return (
      <StaticQuery
        query={graphql`
          query LayoutIndexQuery {
            allFirestoreCategory(sort: { fields: [title], order: ASC }) {
              edges {
                node {
                  id
                  title
                  service
                  lang
                  type
                }
              }
            }
            allCountryNames: allFirestoreCountry(
              sort: { fields: [title], order: ASC }
            ) {
              edges {
                node {
                  id
                  title
                  slug
                  redirect {
                    slug
                  }
                  collection
                  lang
                }
              }
            }
          }
        `}
        render={data => {
          const countries = data.allCountryNames.edges.filter(
            ({ node }) => node.lang === lang
          )
          const categories = data.allFirestoreCategory.edges.filter(
            ({ node }) => node.lang === lang
          )

          return (
            <div className={className}>
              <Helmet>
                <html lang={lang} />
                <link
                  rel='alternate'
                  hreflang={otherLang}
                  href={alternateHref}
                />
              </Helmet>

              <Navigation
                pageContext={pageContext}
                countries={countries}
                categories={categories}
              />

              <div className='content-wrapper'>{children}</div>

              <Footer pageContext={pageContext} />

              <ScrollToTopButton />
            </div>
          )
        }}
      />
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchTopProps = {
  setSearchOptions: navigationActions.setSearchOptions,
  resetFilters: filtersActions.resetFilters
}

export default withTranslation()(connect(null, mapDispatchTopProps)(PageLayout))
