import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button, Transition } from 'semantic-ui-react'
import { navigate } from 'gatsby'

import { links } from '../../utils/constants'
import { wishlistActions } from './state'

class WishlistButton extends Component {
  constructor(props) {
    super(props)
    this.wishButton = React.createRef()
  }

  state = {
    visible: true
  }

  componentDidMount() {
    const componentWidth = this.wishButton.current.offsetWidth

    if (componentWidth < 440) {
      this.setState({ vertical: true })
    }
  }

  toggleItem = (e) => {
    e.stopPropagation()

    const { wishlist, item, wishlistAddItem, wishlistRemoveItem } = this.props

    const itemId = `${item.slug}_${item.version || ''}`
    const isFav = Object.values(wishlist)
      .findIndex(({ id }) => id === itemId) > -1

    if (isFav) {
      wishlistRemoveItem(itemId)
    } else {
      wishlistAddItem({
        id: `${item.slug}_${item.version || ''}`,
        title: `${item.title} ${item.version || ''}`,
        intro: item.intro,
        url: `/destinations/${item.countrySlug}/${item.collection}/${item.slug}`,
        country: item.country,
        image: item.coverImage
      })
    }
    this.setState({ visible: !this.state.visible })
  }

  quickWish = e => {
    e.stopPropagation()

    const { i18n, wishlist, item, wishlistAddItem } = this.props

    const itemId = `${item.slug}_${item.version || ''}`
    const isFav = Object.values(wishlist).findIndex(({ id }) => id === itemId) > -1

    // Add to wishlist if doesn't exists
    if (!isFav) {
      wishlistAddItem({
        id: `${item.slug}_${item.version || ''}`,
        title: `${item.title} ${item.version || ''}`,
        intro: item.intro,
        url: `/destinations/${item.countrySlug}/${item.collection}/${item.slug}`,
        country: item.country,
        image: item.coverImage
      })
    }

    const lang = i18n.language
    navigate(`/${lang}/${links['wishlist'][lang]}`)
  }

  render() {
    const { t, wishlist, item } = this.props

    const itemId = `${item.slug}_${item.version || ''}`
    const isFav = Object.values(wishlist).findIndex(({ id }) => id === itemId) > -1

    const iconStyle = { padding: '15px 0', width: this.state.vertical ? '100%' : '50%' }

    return (
      <div ref={this.wishButton}>
        <Button.Group style={{ width: '100%' }} vertical={this.state.vertical}>
          <Button icon labelPosition='right' onClick={this.quickWish} color='red' style={iconStyle}>
            <div className='nowrap'>{t(`wishlist-button.quick-wish`)}</div>
            <i className='icon large ta-mail' />
          </Button>

          <Button icon labelPosition='right' onClick={this.toggleItem} color='grey' style={iconStyle}>
            <div className='nowrap'>{t(`wishlist-button.wishlist`)}</div>
            <Transition animation='jiggle' duration={500} visible={this.state.visible}>
              <i className={`icon inverted large ${isFav ? 'ta-heart-filled' : 'ta-heart-contur-thin'}`} />
            </Transition>
          </Button>
        </Button.Group>
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ wishlist }) => {
  return { wishlist }
}

const mapDispatchToProps = {
  wishlistAddItem: wishlistActions.wishlistAddItem,
  wishlistRemoveItem: wishlistActions.wishlistRemoveItem,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WishlistButton))
