import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { Segment } from 'semantic-ui-react'
import Img from 'gatsby-image'

import ImageLightbox from './ImageLightbox'
import { getFluidImage } from '../../utils/images'
import { navigationActions } from '../Navigations/state'

const ImageSectionSlider = ({ lightboxId, images = [], setLightbox, withLightbox, aspectRatio = 1, thumbWidth = 300 }) => {
  const settings = {
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1185,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
          // variableWidth: true
        }
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
          // variableWidth: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // variableWidth: true
        }
      }
    ]
  }

  return (
    <Segment basic vertical padded>
      <Slider {...settings}>
        {images.map(({ id, cloudinary, alt, title }) => (
          <div key={id} onClick={() => setLightbox({ image: id, open: lightboxId })}>
            <Img fluid={getFluidImage({ cloudinary, maxWidth: thumbWidth, ar: aspectRatio })} style={{ cursor: 'pointer', margin: '0 5px' }} alt={alt || title} />
          </div>
        ))}
      </Slider>
      {withLightbox && <ImageLightbox id={lightboxId} images={images} />}
    </Segment>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchTopProps = {
  setLightbox: navigationActions.setLightbox
}

export default connect(null, mapDispatchTopProps)(ImageSectionSlider)
