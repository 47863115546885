import { Button, Segment, Grid } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { Link } from '../../locales'
import { withTranslation } from 'react-i18next'
import Checkbox from './Checkbox'
import { isBrowser } from '../../utils/helpers'

const CookieConfirmation = ({ t, i18n }) => {
  const [visible, setVisible] = useState(false)
  const [googleServices, setGoogleServices] = useState(
    isBrowser &&
      window.localStorage.getItem(CookieBanner.googleServices) === 'true'
  )
  const [marketing, setMarketing] = useState(
    isBrowser && window.localStorage.getItem(CookieBanner.marketing) === 'true'
  )
  const [stats, setStats] = useState(
    isBrowser && window.localStorage.getItem(CookieBanner.statistics) === 'true'
  )

  useEffect(() => {
    let saved = window.localStorage.getItem(CookieBanner.saved)
    if (!saved) {
      setVisible(true)
    } else {
      addScipts()
    }

    window.addEventListener('cookie-banner:show', () => {
      setVisible(true)
    })
  }, [])

  const handleAccept = () => {
    setGoogleServices(true)
    setMarketing(true)
    setStats(true)
    window.localStorage.setItem(CookieBanner.googleServices, true)
    window.localStorage.setItem(CookieBanner.marketing, true)
    window.localStorage.setItem(CookieBanner.statistics, true)
    window.localStorage.setItem(CookieBanner.saved, true)
    setVisible(false)
    addScipts()
  }

  const handleDecline = () => {
    setGoogleServices(false)
    setMarketing(false)
    setStats(false)
    window.localStorage.setItem(CookieBanner.googleServices, false)
    window.localStorage.setItem(CookieBanner.marketing, false)
    window.localStorage.setItem(CookieBanner.statistics, false)
    window.localStorage.setItem(CookieBanner.saved, true)
    setVisible(false)
    addScipts()
  }

  const handleSelected = () => {
    window.localStorage.setItem(CookieBanner.googleServices, googleServices)
    window.localStorage.setItem(CookieBanner.marketing, marketing)
    window.localStorage.setItem(CookieBanner.statistics, stats)
    window.localStorage.setItem(CookieBanner.saved, true)
    setVisible(false)
    addScipts()
  }

  const addScipts = () => {
    if (window.localStorage.getItem(CookieBanner.statistics) === 'true') {
      addGooleTag()
    }

    if (window.localStorage.getItem(CookieBanner.googleServices) === 'true') {
      window.dispatchEvent(new Event('cookie-banner:show-map'))
    }
  }

  const addGooleTag = () => {
    if (!document.getElementById('tourasia-google-tag')) {
      const tag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WVZS5Z');
      `

      const s = document.createElement(`script`)
      s.setAttribute('id', 'tourasia-google-tag')

      s.type = `text/javascript`
      s.innerText = tag
      document.body.appendChild(s)
    }
  }

  return (
    <div className={'cookies cookie-banner' + (visible ? ' show' : '')}>
      <Segment
        basic
        textAlign='center'
        padded
        className='cookie-banner-container'
      >
        <Grid>
          <Grid.Row>
            <Grid.Column className='cookie-banner-heading'>
              {t('footer.cookies.heading')}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className='cookie-banner-info'>
              {t('footer.cookies.info')}{' '}
              <a href={t('footer.cookies.privacy-link-url')}>
                {t('footer.cookies.privacy-link-text')}
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className='cookie-banner-checkboxes'>
                <Checkbox
                  checked={true}
                  disabled={true}
                  label={t('footer.cookies.option.necessary')}
                />
                <Checkbox
                  checked={marketing}
                  onChange={() => setMarketing(!marketing)}
                  label={t('footer.cookies.option.marketing')}
                />
                <Checkbox
                  checked={stats}
                  onChange={() => setStats(!stats)}
                  label={t('footer.cookies.option.statistics')}
                />
                <Checkbox
                  checked={googleServices}
                  onChange={() => setGoogleServices(!googleServices)}
                  label={t('footer.cookies.option.google')}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className='cookie-banner-buttons'>
                <Button
                  className='banner-btn'
                  color='red'
                  onClick={handleAccept}
                >
                  {t('footer.cookies.accept')}
                </Button>
                <Button
                  className='banner-btn'
                  color='red'
                  onClick={handleDecline}
                >
                  {t('footer.cookies.decline')}
                </Button>
                <Button
                  className='banner-btn'
                  color='red'
                  onClick={handleSelected}
                >
                  {t('footer.cookies.only-selected')}
                </Button>
                <Link
                  to='/datenschutz'
                  lang={i18n.language}
                  aria-label={'title'}
                  className='banner-btn'
                >
                  <Button color='red'>{t('footer.cookies.privacy-btn')}</Button>
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  )
}

export const CookieBanner = {
  saved: 'tourasiaCookieBannerSaved',
  googleServices: 'tourasiaCookieBannerGoogleServices',
  marketing: 'tourasiaCookieBannerMarketing',
  statistics: 'tourasiaCookieBannerStatics',
}

export default withTranslation()(CookieConfirmation)
