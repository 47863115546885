import React from 'react'
import { Segment, Container, Header, Label } from 'semantic-ui-react/dist/commonjs'
import { colorRed } from '../../utils/constants'
import { unescape } from '../../utils/helpers'

import StarCounter from '../Common/StarCounter'

const PageHeader = ({ categories, stars, title, subtitle, intro }) => {
  return (
    <Segment padded='very' textAlign='center' className='gradient' inverted vertical>
      <Container>
        {categories && categories.map((category) => (
          <Label key={category} as='a' color='red' style={{ marginBottom: 10, cursor: 'default' }}> {category} </Label>
        ))}

        <div className='page-header'>
          <Header as='h1' color='red'>
            <span className='page-header-title'>{unescape(title)}</span>
            <StarCounter count={stars} color={colorRed} />
          </Header>
        </div>

        {subtitle && <Header as='h4'><div dangerouslySetInnerHTML={{ __html: unescape(subtitle) }} /></Header>}

        {intro && <Header.Subheader><div dangerouslySetInnerHTML={{ __html: unescape(intro) }} /></Header.Subheader>}
      </Container>
    </Segment>
  )
}

export default PageHeader
