import React, { Component } from 'react'
import { Icon, List } from 'semantic-ui-react'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share'

class Share extends Component {
  state = { shareUrl: '' }

  componentDidMount () {
    this.setState({
      origin: window.location.origin,
      pathname: window.location.pathname
    })
  }

  render () {
    const { size = 'large', color = 'red', url = '' } = this.props
    const { origin, pathname } = this.state

    const shareUrl = `${origin}${url || pathname}`

    return (
      <List horizontal>
        <List.Item>
          <FacebookShareButton url={shareUrl}>
            <Icon name='facebook' link size={size} color={color} aria-label='share on facebook' />
          </FacebookShareButton>
        </List.Item>
        <List.Item>
          <TwitterShareButton url={shareUrl}>
            <Icon name='twitter' link size={size} color={color} aria-label='share on twitter' />
          </TwitterShareButton>
        </List.Item>
        <List.Item>
          <EmailShareButton url={shareUrl}>
            <Icon name='mail' link size={size} color={color} aria-label='share by email' />
          </EmailShareButton>
        </List.Item>
      </List>
    )
  }
}

export default Share
